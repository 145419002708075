// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admit-card-index-js": () => import("./../../../src/pages/AdmitCard/index.js" /* webpackChunkName: "component---src-pages-admit-card-index-js" */),
  "component---src-pages-applyfornewcourse-index-js": () => import("./../../../src/pages/applyfornewcourse/index.js" /* webpackChunkName: "component---src-pages-applyfornewcourse-index-js" */),
  "component---src-pages-certificate-id-index-js": () => import("./../../../src/pages/CertificateID/index.js" /* webpackChunkName: "component---src-pages-certificate-id-index-js" */),
  "component---src-pages-certificates-index-js": () => import("./../../../src/pages/Certificates/index.js" /* webpackChunkName: "component---src-pages-certificates-index-js" */),
  "component---src-pages-chalan-index-js": () => import("./../../../src/pages/Chalan/index.js" /* webpackChunkName: "component---src-pages-chalan-index-js" */),
  "component---src-pages-course-instructions-index-js": () => import("./../../../src/pages/courseInstructions/index.js" /* webpackChunkName: "component---src-pages-course-instructions-index-js" */),
  "component---src-pages-courses-index-js": () => import("./../../../src/pages/courses/index.js" /* webpackChunkName: "component---src-pages-courses-index-js" */),
  "component---src-pages-email-verification-index-js": () => import("./../../../src/pages/emailVerification/index.js" /* webpackChunkName: "component---src-pages-email-verification-index-js" */),
  "component---src-pages-iit-signup-index-js": () => import("./../../../src/pages/IITSignup/index.js" /* webpackChunkName: "component---src-pages-iit-signup-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-notification-index-js": () => import("./../../../src/pages/Notification/index.js" /* webpackChunkName: "component---src-pages-notification-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-signup-index-js": () => import("./../../../src/pages/signup/index.js" /* webpackChunkName: "component---src-pages-signup-index-js" */),
  "component---src-pages-upload-chalan-index-js": () => import("./../../../src/pages/uploadChalan/index.js" /* webpackChunkName: "component---src-pages-upload-chalan-index-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

